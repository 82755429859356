<template>
  <v-main>
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
  </v-main>
</template>

<script>
export default {
  name: "BaseView",
};
</script>
<style lang="sass" scoped>

.v-main
  background-color: var(--bgColor)
  // background-image: url('~@/assets/home-hero-3.webp')
  background-size: cover
</style>
